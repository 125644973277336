import File from "../models/file";
import Survey from "../models/survey";
import SurveyArea from "../models/surveyArea";
import SurveyItem from "../models/surveyItem";
import httpService from "./httpService";

const apiEndpoint = "/surveys";

const getSurveys = (idClientAccount: number) => {
  return httpService.get<Survey[]>(
    `${apiEndpoint}/getSurveys?idClientAccount=${idClientAccount}`
  );
};

const getSurveyById = (idSurvey: number) => {
  return httpService.get<Survey>(
    `${apiEndpoint}/getSurveyById?idSurvey=${idSurvey}`
  );
};

const createSurvey = (survey: Survey) => {
  return httpService.post<Survey>(`${apiEndpoint}/createSurvey`, survey);
};

const updateSurvey = (survey: Survey) => {
  return httpService.post<Survey>(`${apiEndpoint}/updateSurvey`, survey);
};

const deleteSurvey = (survey: Survey) => {
  return httpService.delete<Survey>(`${apiEndpoint}/deleteSurvey`, {
    data: survey,
  });
};

const generateSurveyCode = () => {
  return httpService.get<string>(`${apiEndpoint}/generateSurveyCode`);
};

const generateSurveyPassCode = () => {
  return httpService.get<string>(`${apiEndpoint}/generateSurveyPassCode`);
};

const createPdfSurvey = (idSurvey: number) => {
  return httpService.post<string>(`${apiEndpoint}/createPdfSurvey`, {
    idSurvey,
  });
};

const accessSurvey = (surveyCode: string, surveyPassCode: string) => {
  return httpService.post<boolean>(`${apiEndpoint}/accessSurvey`, {
    surveyCode,
    surveyPassCode,
  });
};

const getSurveyBySurveyCode = (surveyCode: string, surveyPassCode: string) => {
  return httpService.post<Survey>(`${apiEndpoint}/getSurveyBySurveyCode`, {
    surveyCode,
    surveyPassCode,
  });
};

const getSurveyGeneralInstructions = (surveyCode: string) => {
  return httpService.get<string>(
    `${apiEndpoint}/getSurveyGeneralInstructions?surveyCode=${surveyCode}`
  );
};

const updateSurveyItemFields = (surveyItem: SurveyItem) => {
  return httpService.post(`${apiEndpoint}/updateSurveyItemFields`, surveyItem);
};

const uploadFile = (formData: any) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return httpService.post<File>(`${apiEndpoint}/uploadFile`, formData, {
    headers,
  });
};

const createSurveyItem = (surveyResourceId: string) => {
  return httpService.post<SurveyItem>(`${apiEndpoint}/createSurveyItem`, {
    surveyResourceId,
  });
};

const deleteSurveyItem = (
  surveyResourceId: string,
  surveyItemResourceId: string
) => {
  return httpService.post<SurveyItem[]>(`${apiEndpoint}/deleteSurveyItem`, {
    surveyResourceId,
    surveyItemResourceId,
  });
};

const moveSurveyItemUpDown = (
  surveyResourceId: string,
  surveyItemResourceId: string,
  moveUp: boolean
) => {
  return httpService.post<SurveyItem[]>(`${apiEndpoint}/moveSurveyItemUpDown`, {
    surveyResourceId,
    surveyItemResourceId,
    moveUp,
  });
};

const deleteSurveyItemFile = (
  surveyResourceId: string,
  surveyItemResourceId: string,
  fileResourceId: string
) => {
  return httpService.post<SurveyItem>(`${apiEndpoint}/deleteSurveyItemFile`, {
    surveyResourceId,
    surveyItemResourceId,
    fileResourceId,
  });
};

const moveSurveyItemFileUpDown = (
  surveyResourceId: string,
  surveyItemResourceId: string,
  fileResourceId: string,
  moveUp: boolean
) => {
  return httpService.post<SurveyItem>(
    `${apiEndpoint}/moveSurveyItemFileUpDown`,
    {
      surveyResourceId,
      surveyItemResourceId,
      fileResourceId,
      moveUp,
    }
  );
};

const completeSurvey = (
  surveyCode: string,
  surveyPassCode: string,
  surveySummaryNotes: string
) => {
  return httpService.post(`${apiEndpoint}/completeSurvey`, {
    surveyCode,
    surveyPassCode,
    surveySummaryNotes,
  });
};

const getSurveyAreas = () => {
  return httpService.get<SurveyArea[]>(`${apiEndpoint}/getSurveyAreas`);
};

const surveyService = {
  getSurveys,
  getSurveyById,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  generateSurveyCode,
  generateSurveyPassCode,
  createPdfSurvey,
  accessSurvey,
  getSurveyBySurveyCode,
  uploadFile,
  updateSurveyItemFields,
  createSurveyItem,
  deleteSurveyItem,
  moveSurveyItemUpDown,
  deleteSurveyItemFile,
  moveSurveyItemFileUpDown,
  completeSurvey,
  getSurveyAreas,
  getSurveyGeneralInstructions,
};

export default surveyService;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import Moment from "react-moment";
import dateTimeHelper from "../util/datetimeHelper";
import Footer from "../components/work/Footer";
import Survey from "../models/survey";
import surveyService from "../services/surveyService";
import SurveyItemsEdit from "../components/common/SurveyItemsEdit";
import SurveyStatus from "../models/surveyStatus";
import ModalWindow from "../components/common/ModalWindow";
import SurveyItem from "../models/surveyItem";
import surveyItemsEditCommon from "../components/common/surveyItemsEditCommon";
import { Helmet } from "react-helmet-async";

const ExternalSurveyEdit: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { code: surveyCode } = useParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [survey, setSurvey] = useState<Survey | undefined>();
  const [editedSurveyItems, setEditedSurveyItems] = useState<SurveyItem[]>([]);
  const [surveySummaryNotes, setSurveySummaryNotes] = useState<string>("");

  const [surveyGeneralInstructions, setSurveyGeneralInstructions] =
    useState<string>("");

  const [isAgreeTermsAndConditions, setIsAgreeTermsAndConditions] =
    useState<boolean>(false);

  const [surveyPassCode, setSurveyPassCode] = useState<string>("");
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const [showCompleteSurveyWindow, setShowCompleteSurveyWindow] =
    useState<boolean>(false);

  const [isCompleteSurveyOkEnabled, setIsCompleteSurveyOkEnabled] =
    useState<boolean>(false);

  const [showSurveryErrorsWindow, setShowSurveyErrorsWindow] =
    useState<boolean>(false);
  const [surveyErrors, setSurveyErrors] = useState<string>();

  useEffect(() => {
    const init = async (surveyCode: string) => {
      try {
        const { data: generalInstructions } =
          await surveyService.getSurveyGeneralInstructions(surveyCode);

        if (generalInstructions) {
          setSurveyGeneralInstructions(generalInstructions);
        } else {
          setIsAgreeTermsAndConditions(true);
        }
      } catch (error) {
        // TODO: show error with toastr
      }
    };

    if (surveyCode) {
      init(surveyCode);
    }
  }, [surveyCode]);

  const onLogoClick = () => {
    navigate("/");
  };

  const onSurveyPassCodeChanged = (event: any) => {
    const newValue = event.target.value;
    setSurveyPassCode(newValue);
  };

  const onAccessSurveySubmit = async (event: any) => {
    event.preventDefault();

    if (!surveyCode) {
      return;
    }

    setErrorMessage("");

    try {
      await surveyService.accessSurvey(surveyCode, surveyPassCode);

      const { data: survey } = await surveyService.getSurveyBySurveyCode(
        surveyCode,
        surveyPassCode
      );

      setIsVerified(true);

      if (survey) {
        setSurvey(survey);
        setSurveySummaryNotes(survey.surveySummaryNotes);
        updateIsCompleteSurveyOkEnabled(survey.surveySummaryNotes);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
    }
  };

  const getAddress = (survey: Survey) => {
    const address = survey.location.address;
    let addressInfo = survey.location.address.addressLine1;

    if (address.addressLine2) {
      addressInfo += "," + address.addressLine2;
    }

    addressInfo += ", " + address.city;

    addressInfo += ", " + address.state;

    if (address.county) {
      addressInfo += ", " + address.county;
    }

    addressInfo += ", " + address.zip;

    return addressInfo;
  };

  const onCompleteSurveyConfirmClick = async () => {
    if (editedSurveyItems.length === 0) {
      setSurveyErrors(
        "The survey can't be completed without any items added to it. At least 1 item must be added."
      );
      setShowSurveyErrorsWindow(true);
      return;
    }

    const canNotAddNew =
      surveyItemsEditCommon.canNotAddNewSurveyItem(editedSurveyItems);

    if (canNotAddNew) {
      setSurveyErrors(
        "The survey contains errors or required fileds and can't be completed."
      );
      setShowSurveyErrorsWindow(true);
      return;
    }

    setShowCompleteSurveyWindow(true);
  };

  const onCompleteSurveyOk = async () => {
    if (!surveyCode || !survey) {
      return;
    }

    setShowCompleteSurveyWindow(false);

    await surveyService.completeSurvey(
      surveyCode,
      surveyPassCode,
      surveySummaryNotes
    );

    const newSurvey = { ...survey, surveyStatus: SurveyStatus.Completed };
    setSurvey(newSurvey);
  };

  const onCompleteSurveyCancel = () => {
    setShowCompleteSurveyWindow(false);
  };

  const onCloseErrorWindow = () => {
    setShowSurveyErrorsWindow(false);
    setSurveyErrors(undefined);
  };

  const onSurveySummaryNotesChanged = (event: any) => {
    const surveySummaryNotes = event.target.value;

    setSurveySummaryNotes(surveySummaryNotes);

    updateIsCompleteSurveyOkEnabled(surveySummaryNotes);
  };

  const updateIsCompleteSurveyOkEnabled = (surveySummaryNotes: string) => {
    let isCompleteSurveyOkEnabled = true;

    if (surveySummaryNotes === null || surveySummaryNotes.trim() === "") {
      isCompleteSurveyOkEnabled = false;
    }

    setIsCompleteSurveyOkEnabled(isCompleteSurveyOkEnabled);
  };

  return (
    <div>
      <Helmet
        onChangeClientState={() => setTimeout(() => setIsLoading(false), 500)}
      >
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/app-work.css"
        />
      </Helmet>
      {isLoading && <div></div>}
      {!isLoading && (
        <>
          <header
            id="header"
            className="header fixed-top d-flex align-items-center"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="logo d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={onLogoClick}
              >
                <img src={Logo} alt="" />
                <span className="d-lg-block">Work Order Express Survey</span>
              </div>
            </div>
          </header>
          <main id="mainfull" className="main">
            {!isVerified && (
              <div className="pagetitle">
                {surveyGeneralInstructions && (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: surveyGeneralInstructions,
                      }}
                    ></div>
                  </>
                )}
                <form onSubmit={onAccessSurveySubmit}>
                  <h1 className="mb-3">Survey Pass Code</h1>
                  <input
                    type="password"
                    className="form-control mb-3"
                    id="surveyPassCode"
                    autoComplete="new-password"
                    value={surveyPassCode ?? ""}
                    onChange={onSurveyPassCodeChanged}
                  />
                  <input
                    type="checkbox"
                    id="showPassCode"
                    onChange={(e) => {
                      const passEl: any =
                        document.getElementById("surveyPassCode");
                      if (passEl) {
                        passEl.type = e.target.checked ? "text" : "password";
                      }
                    }}
                  />
                  <label
                    style={{ marginLeft: "4px" }}
                    htmlFor="showPassCode"
                    className="form-label"
                  >
                    Show Pass Code
                  </label>
                  {surveyGeneralInstructions && (
                    <div>
                      <input
                        type="checkbox"
                        id="agreeTermsAndConditions"
                        onChange={(e) => {
                          setIsAgreeTermsAndConditions(
                            e.target.checked ? true : false
                          );
                        }}
                      />
                      <label
                        style={{ marginLeft: "4px" }}
                        htmlFor="agreeTermsAndConditions"
                        className="form-label"
                      >
                        Agree to Terms and Conditions
                      </label>
                    </div>
                  )}

                  <button
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    type="submit"
                    disabled={!isAgreeTermsAndConditions}
                  >
                    Access Survey
                  </button>
                </form>
              </div>
            )}
            {errorMessage !== "" && (
              <div className="col-md-12 text-center mt-3 mb-3">
                <div className="error-message">{errorMessage}</div>
              </div>
            )}
            {isVerified &&
              survey &&
              survey.surveyStatus === SurveyStatus.Open && (
                <>
                  <div className="pagetitle">
                    <h1>
                      <span>{survey.surveyTitle}</span>
                      {survey.surveyTitle && (
                        <>
                          {", "}
                          <span>
                            <Moment format={dateTimeHelper.dateFormat}>
                              {survey.surveyDate}
                            </Moment>
                          </span>
                        </>
                      )}
                    </h1>
                  </div>
                  <section className="section">
                    <div className="mb-4 card p-2 p-lg-4">
                      <div style={{ fontWeight: "bold" }}>
                        {survey.location.locationName}
                      </div>
                      <div>{getAddress(survey)}</div>
                    </div>
                    {survey.surveyInstructions && (
                      <div className="mb-4 card p-2 p-lg-4">
                        <h5 className="card-title" style={{ padding: "0px" }}>
                          Instructions
                        </h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: survey.surveyInstructions.replace(
                              /\n/g,
                              "<br />"
                            ),
                          }}
                        ></div>
                      </div>
                    )}
                    <SurveyItemsEdit
                      surveyItems={survey.surveyItems}
                      surveyResourceId={survey.resourceId}
                      onSurveyItemsChanged={(surveyItems: SurveyItem[]) => {
                        setEditedSurveyItems(surveyItems);
                      }}
                    />
                    <button
                      className="btn btn-primary mt-2"
                      style={{ width: "100%" }}
                      onClick={() => onCompleteSurveyConfirmClick()}
                    >
                      Complete Survey
                    </button>
                  </section>
                </>
              )}
            {isVerified &&
              survey &&
              survey.surveyStatus === SurveyStatus.Completed && (
                <div className="pagetitle">
                  <h1>
                    <span>Survey Completed</span>
                  </h1>
                </div>
              )}
          </main>
          <Footer isFullWidth />
          {showCompleteSurveyWindow && (
            <ModalWindow
              title="Complete Survey"
              onOk={onCompleteSurveyOk}
              onCancel={onCompleteSurveyCancel}
              isOkEnabled={isCompleteSurveyOkEnabled}
            >
              <div>
                <div className="mb-2">
                  Are you sure you want to complete the survey?
                </div>
                <div className="mb-1">
                  Before completing this survey, please add your summary notes
                </div>
                <textarea
                  className="form-control"
                  id="surveySummaryNotes"
                  value={surveySummaryNotes}
                  onChange={onSurveySummaryNotesChanged}
                  style={{ height: "100px" }}
                />
              </div>
            </ModalWindow>
          )}
          {showSurveryErrorsWindow && (
            <ModalWindow
              title="Survey Errors"
              isShowCancel={false}
              onOk={onCloseErrorWindow}
              isErrorWindow={true}
            >
              <div>{surveyErrors}</div>
            </ModalWindow>
          )}
        </>
      )}
    </div>
  );
};

export default ExternalSurveyEdit;

import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../components/home/Header";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const navigate = useNavigate();

  // avoid FOUC - so called Flash of Unstyled Content
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onGettingStartedClick = () => {
    navigate("/login");
  };

  return (
    <>
      <Helmet
        onChangeClientState={() => setTimeout(() => setIsLoading(false), 500)}
      >
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/app-home.css"
        />
      </Helmet>
      {isLoading && <div></div>}
      {!isLoading && (
        <>
          <Header />
          <main className="main">
            {/* <!-- Hero Section --> */}
            <section id="hero" className="hero section under-header">
              <div className="container">
                <div className="row gy-4">
                  <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                    <h2 data-aos="fade-up">Welcome to Work Order Express</h2>
                    <p data-aos="fade-up" data-aos-delay="100">
                      Your Ultimate Property Management Solution
                    </p>
                    <div
                      className="d-flex flex-column flex-md-row"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div
                        className="btn-get-started"
                        onClick={() => navigate("/about")}
                      >
                        Get Started <i className="bi bi-arrow-right"></i>
                      </div>
                      {/* <a
                        href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                        className="glightbox btn-watch-video d-flex align-items-center justify-content-center ms-0 ms-md-4 mt-4 mt-md-0"
                      >
                        <i className="bi bi-play-circle"></i>
                        <span>Watch Video</span>
                      </a> */}
                    </div>
                  </div>
                  {/* <div
                    className="col-lg-6 order-1 order-lg-2 hero-img"
                    data-aos="zoom-out"
                  >
                    <img
                      src="assets/img/hero-img.png"
                      className="img-fluid animated"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </section>
            {/* <!-- /Hero Section --> */}
            <section id="about" className="about-home">
              <div className="container">
                <div className="row content">
                  <div className="col-lg-6">
                    <h2>Streamline Your Property Management</h2>
                    <h3>
                      Are you a landlord, realtor, or property maintenance
                      professional looking for an efficient and reliable way to
                      manage your properties? Work Order Express is here to
                      revolutionize the way you conduct property surveys,
                      ensuring accuracy, efficiency, and peace of mind.
                    </h3>
                  </div>
                  <div className="col-lg-6 pt-4 pt-lg-0">
                    <p>Why Choose Work Order Express?</p>
                    <ul>
                      <li>
                        <i className="ri-check-double-line"></i>{" "}
                        <b>Simplified, Comprehensive Surveys</b>
                        <br />
                        Our intuitive app allows you to conduct detailed
                        property surveys with ease. From documenting structural
                        integrity to noting cosmetic details, WOX ensures no
                        aspect is overlooked.
                      </li>
                      <li>
                        <i className="ri-check-double-line"></i>{" "}
                        <b>Tailored for Professionals</b>
                        <br />
                        Whether you manage a single property or an entire
                        portfolio, our app is designed to cater to your specific
                        needs. Landlords can ensure their properties are
                        maintained, realtors can present accurate property
                        conditions to clients, and maintenance companies can
                        streamline their inspection processes.
                      </li>
                      <li>
                        <i className="ri-check-double-line"></i>{" "}
                        <b>Save Time and Reduce Costs</b>
                        <br />
                        Automate your survey process and eliminate the need for
                        manual paperwork. With Work Order Express, you can
                        quickly generate comprehensive reports, saving you
                        valuable time and reducing operational costs.
                      </li>
                      <li>
                        <i className="ri-check-double-line"></i>{" "}
                        <b>Reliable and Secure Data</b>
                        <br />
                        Your data is securely stored and easily accessible
                        whenever you need it. Our robust encryption ensures that
                        all property details remain confidential and protected.
                      </li>
                    </ul>
                    <p className="fst-italic">We are here to help.</p>
                  </div>
                </div>
              </div>
            </section>
            {/* <section id="why-us" className="why-us">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="box">
                      <span>01</span>
                      <h4>Lorem Ipsum</h4>
                      <p>
                        Ulamco laboris nisi ut aliquip ex ea commodo consequat.
                        Et consectetur ducimus vero placeat
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="box">
                      <span>02</span>
                      <h4>Repellat Nihil</h4>
                      <p>
                        Dolorem est fugiat occaecati voluptate velit esse. Dicta
                        veritatis dolor quod et vel dire leno para dest
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 mt-4 mt-lg-0">
                    <div className="box">
                      <span>03</span>
                      <h4> Ad ad velit qui</h4>
                      <p>
                        Molestiae officiis omnis illo asperiores. Aut doloribus
                        vitae sunt debitis quo vel nam quis
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </main>
        </>
      )}
    </>
  );
};

export default Home;

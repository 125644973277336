import SurveyItem from "../../models/surveyItem";

const canNotAddNewSurveyItem = (surveyItems: SurveyItem[]): boolean => {
  const canNotAddNew =
    surveyItems.find(
      (s) =>
        s.conditionType === null ||
        s.comments === null ||
        s.comments.trim() === ""
    ) !== undefined;

  return canNotAddNew;
};

const surveyItemsEditCommon = {
  canNotAddNewSurveyItem,
};

export default surveyItemsEditCommon;
